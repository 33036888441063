<template>
   <template v-if="accessGranted"> <!-- attach to the vue-router-view? -->
      <TripSummary />

      <template v-if="showUnreadMessages">

         <WarningMessage
            icon="comment"
            :isClickable="true"
            @click="goToCommunicationSection"
            :title="`${$t('new_message_from')} ${responsibleName}`"
         >
            {{ $t('check_communication_section_for_details') }}
         </WarningMessage>
      </template>

      <div class="l-flex-grid fill-remaining-space h-page-block-separation">
         <div class="l-flex-grid__item">
            <div class="h-white-block">
               <TimeLine />
            </div>
         </div>

         <div class="l-flex-grid__item">
            <TripMap />
         </div>
      </div>

      <MealPlans :isMealplanEditable="canEditMealPlans" />

      <SuggestedPrograms v-if="showSuggestedPrograms"/>
      <AddProgramModal />
      <ProgramInfoModal />
      <CookiesDetailsModal />
      <PendingOfferWarningModal />
   </template>

   <div v-if="unauthorized"> {{ $t('access_unauthorized_message') }} </div>
</template>

<script>
   import navigation from '@/mixins/navigation.js';
   import TripMap from '@/components/dashboard/TripMap.vue';
   import MealPlans from '@/components/shared/MealPlans.vue';
   import WarningMessage from '@/components/shared/WarningMessage';
   import { tripStatusName as status } from '@/constants/global.js';
   import stopLoadingOnMounted from '@/mixins/stopLoadingOnMounted.js';
   import TimeLine from '@/components/dashboard/timeline/TimeLine.vue';
   import TripSummary from '@/components/dashboard/trip_summary/TripSummary.vue';
   import CookiesDetailsModal from '@/components/dashboard/CookiesDetailsModal.vue';
   import AddProgramModal from '@/components/dashboard/suggested_programs/AddProgramModal.vue';
   import ProgramInfoModal from '@/components/dashboard/suggested_programs/ProgramInfoModal.vue';
   import SuggestedPrograms from '@/components/dashboard/suggested_programs/SuggestedPrograms.vue';
   import PendingOfferWarningModal from '@/components/dashboard/suggested_programs/PendingOfferWarningModal.vue';
   
   import { mapState,mapGetters } from 'vuex';

   export default {
      name: 'Dashboard',
      mixins: [
         stopLoadingOnMounted,
         navigation,
      ],
      components: {
         AddProgramModal,
         CookiesDetailsModal,
         MealPlans,
         PendingOfferWarningModal,
         ProgramInfoModal,
         SuggestedPrograms,
         TimeLine,
         TripMap,
         TripSummary,
         WarningMessage,
      },
      computed: {
         ...mapState({
            accessGranted: state => state.accessGranted,
            hasWriteAccessMode: state => state.writeAccessMode,
            isDb: state => state.isDb,
            canRequestPrograms: state => state.canRequestPrograms,
            responsible: state => state.responsible,
            suggestedPrograms: state => state.dashboard.suggestedPrograms,
            unauthorized: state => state.unauthorized,
            offers: state => state.offers,
            tripStatus: state => state.tripStatus,
         }),

         ...mapGetters([
            "unreadMessagesCount"
         ]),

         hasValidOffer() {
            if(this.offers == null) {
               return false;
            }

            return this.offers.last.status === 'sent';
         },

         canEditMealPlans() {
            if (
                  this.hasValidOffer 
                  || this.tripStatus == status.TRIP_ARCHIVED
                  || this.tripStatus == status.OFFER_EXPIRED
                  || !this.canRequestPrograms // 30 days before trip
               ) {
               return false;
            }

            return this.hasWriteAccessMode || this.isDb;
         },

         showSuggestedPrograms() {
            return this.suggestedPrograms.length > 0 && this.canRequestPrograms;
         },

         showUnreadMessages() {
            return this.unreadMessagesCount > 0;
         },
         responsibleName() {
            if (!this.responsible) {
               return this.$t("db_user_not_assigned_yet");
            }

            return this.responsible.name;
         },
      },
   }
</script>

<style lang="less">
   .p-dashboard {
      .b-suggested-programs {
         margin-top: var(--page-block-separation);
      }

      .b-unread-message-warning {
         margin-top: 20px;
      }

      .l-flex-grid.fill-remaining-space .l-flex-grid__item {
         .h-flex-row-left-top;

         > * { // make the white-block and map take the full height
            width: 100%;
            .align-self(stretch);
         }
      }

      @media only screen and (max-width : @breakpoint-md) {
         .l-flex-grid {
            .flex-direction(column-reverse);
         }

         .l-flex-grid,
         .l-flex-grid__item {
            width: 100%;
            margin-left: 0;
         }
      }
   }
</style>
